import React from 'react'
import Header from '../Header/Header.jsx'
import './Hero.css'
import hero_image from '../../assets/hero_image.png'
import hero_image_back from '../../assets/hero_image_back.png'
import Heart from '../../assets/heart.png'
import Calories from '../../assets/calories.png'
import {motion} from 'framer-motion'
import NumberCounter from 'number-counter'
const hero = () => {
    const transition = {type: 'spring', duration: 3, repeat: Infinity}
    const mobile = window.innerWidth<=768 ? true : false
  return (
    <div className="hero" id='home'>
        <div className="blur blur-hero"></div>
        <div className="left-h">
            {/* /// Header ///// */}
            <Header/>
            {/* //////////// */}
            {/* The best ad */}

            <div className="the-best-ad">
                <motion.div
                initial={{left: mobile ? "160px" : "200px"}}
                whileInView={{left: '8px'}}
                transition={{...transition,type: 'tween'}}
                ></motion.div>
                <span>The best fitness club in the town</span>
            </div>
            {/* ////////////////////////// */}

            <div className="hero-text">
                <div>
                    <span className='stroke-text'>Shape </span>
                    <span>Your</span>
                </div>

                <div>
                    <span>Ideal Body</span>
                </div>

                <div>
                    <span> Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quisquam magni quia perspiciatis aut ad porro fugiat? Nobis ipsum corrupti dolore odit.
                    </span>
                </div>
            </div>

            <div className="figures">
                <div>
                    <span>
                        <NumberCounter end={140} start={100} delay='500ms' preFix="+"/>
                    </span>
                    <span>Expert coaches</span>
                </div>
                <div>
                    <span><NumberCounter end={978} start={900} delay='500ms' preFix="+"/></span>
                    <span>members joined</span>
                </div>
                <div>
                    <span><NumberCounter end={50} start={0} delay='500ms' preFix="+"/></span>
                    <span>fitness programs</span>
                </div>
            </div>

            {/* hero buttons */}
            <div className="hero-button">
                <button className='btn'>Get Started</button>
                <button className='btn'>Learn More</button>
            </div>
        </div>


        <div className="right-h">
            <button className='btn'>Join Now</button>
            <motion.div 
            initial={{right: '-1rem'}}
            whileInView={{right: '4rem'}}
            transition={{...transition,yoyo: onanimationend}}
            className="heart-rate">
                <motion.img 
                animate={{scale: 1.3}}
                transition={{transition,yoyo: Infinity,delay: 2.5}}
                src={Heart} alt="" />
                <span>Heart Rate</span>
                <span>116 BPM</span>
            </motion.div>

            <img src={hero_image} alt="" className="hero-image" />
            <img src={hero_image_back} alt="" className="hero-image_back" />

            <motion.div 
            initial={{scale: 0}}
            whileInView={{scale: 1}}
            transition={{...transition,type: 'tween',yoyo: onanimationend}}
            className="calories">
                <img src={Calories} alt="" />
                <div>
                    <span>Calories Burned</span>
                    <span>220 kcal</span>
                </div>
            </motion.div>
        </div>
    </div>
  )
}

export default hero
